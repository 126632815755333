import $ from 'jquery'
import Rails from '@rails/ujs'
import Template from '../src/biz_ssr/components/Template'
import Flash from '../src/biz_ssr/components/Flash'

import '../stylesheets/biz_ssr/application.scss'

import.meta.glob('../images/**/*')

if (!window._rails_loaded) {
  Rails.start()
}

$(() => {
  // 共通領域
  new Template()

  // コンポーネント
  $('.c-flash').each((i, el) => { new Flash({ el }) })
})
