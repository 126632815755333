import $ from 'jquery'

class Template {
  constructor() {
    this.$headerMenuName  = $('.l-header__menu__name')
    this.$headerMenuLinks  = $('.l-header__menu__links')
    this.$body = $('body')
    this.headerMenuHandling()

    this.$hamburger = $('.l-header .l-header__hamburger')
    this.$menu = $('.l-menu')
    this.$body.on('click', 'a.l-menu__name', () => this.initMenu())
    this.$menu.on('click', (e) => {
      if (!$(e.target).closest('.l-menu__wrapper').length) this.initMenu()
    })
    this.$hamburger.on('click', () => this.toggleMenu())
    this.$headerMenuLinks.on('click', 'a', () => { this.toggleHeaderMenu() })
  }

  headerMenuHandling() {
    const { $headerMenuName, $headerMenuLinks, $body } = this

    $headerMenuName.on('click', () => {
      this.toggleHeaderMenu()
      return false
    })
    $body.on('click', () => {
      $headerMenuLinks.removeClass('is-open')
    })
    $headerMenuLinks.on('click', (e) => e.stopPropagation())
  }

  toggleHeaderMenu() {
    const { $headerMenuName, $headerMenuLinks } = this
    $headerMenuName.toggleClass('is-open')
    $headerMenuLinks.toggleClass('is-open')
  }

  toggleMenu() {
    const { $menu, $hamburger } = this
    $($menu).toggleClass('is-open')
    $($hamburger).toggleClass('is-open')
    if ($($hamburger).hasClass('is-open') ) {
      $($hamburger).removeClass('is-close')
      this.fixed()
    } else {
      $($hamburger).addClass('is-close')
      this.release()
    }
  }

  initMenu() {
    const { $menu, $hamburger } = this
    $($menu).removeClass('is-open')
    $($hamburger).removeClass('is-open')
    this.release()
  }

  fixed(){
    this.scrollY = $(window).scrollTop()
    $('body').css({
      'position': 'fixed',
      'width': '100%',
      'top': -1 * this.scrollY
    })
  }

  release(){
    $('body').css({
      'position': 'relative',
      'width': '',
      'top': ''
    })
    $(window).scrollTop(this.scrollY)
  }
}

export default Template
