import $ from 'jquery'

class Flash {
  constructor({ el }) {
    this.$el = $(el)
    this.$close = this.$el.find('.js-close-flash')
    this.$close.on('click', () => this.close())
  }

  close() {
    this.$el.fadeOut()
  }
}

export default Flash
